@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PPMori';
  src: url('./public/PPMori-Variable.ttf') format('truetype'),
       url('./public/PPMori-Variable.woff2') format('woff2'),
       url('./public/PPMori-Variable.woff') format('woff');
  font-weight: 100 900;
}

.chat-container {
  p, h1, h2, h3, h4, h5, h6 , span, ul, li, a {
    color:white !important;
  }
}

:root {
  --bg: #1A1A1A;
  --dark: #2E2E2E;

  --grey: #3F4445;
  --light-grey: #272B29;

  --green: #C1F553;
  --teal: #21DFBD;
  --hilight: #F956F2;

  --admin: #CBD5E1;
  --white: #fff;
  --black: #000;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  scrollbar-color: var(--grey) var(--bg);
}


html, body, #root {
  overflow: auto;
  position: relative;
}

html, body, #root, .layout{
  width: 100vw;
  height: 100vh;
  position: relative;
  max-height: 100vh;
}

img{
  pointer-events: none;
}

*,
*::before,
*::after{box-sizing:border-box;}

  body {
    margin: 0;
    font-family: PPMori, -apple-system, BlinkMacSystemFont, 'Segoe UI';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F3F4F6;
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
  }

  h1{
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  h2{
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin: 0;
    padding: 0;

  }

  p{
    color: var(--black);
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-weight: 300;
  }

  .max-view{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100vw;
    min-width: 100vw;
    height: 100%;
    background-color: var(--bg);
  }

  .section-view{
    width: calc(100vw - 100px);
    height: 100%;
    display: flex;
  }

  .content-view{
    min-width: 100%;
    width: 100%;
    height: auto; 
    display: flex;
    min-height: 100%;
    flex-direction: column;
  }

  .fade-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  .fade-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--dark);
  border-bottom-color: var(--hilight);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

.markdown{
  line-height: 1.5;
  color: var(--black);
  font-weight: 300;
}
.chat-container .markdown {
  background-color: #3A4E69;
  border-radius: 16px;
}
.assistant .markdown {
  padding-top: 5px;
  padding-bottom: 5px;
}
.markdown a{
  color: var(--black);
}

.markdown p{
  color: var(--black);
  margin: 8px 0;
  font-weight: 300;
}
.markdown h3, .markdown li{
  margin: 16px 0;
}

.markdown hr, .markdown h2{
  margin: 24px 0;
}

.markdown strong, .markdown b{
  font-weight: bold;
}

.markdown img{
  width: 100%;
  height: auto;
  display: block;
  margin: 64px 0;
  max-width: 500px;
  border-radius: 16px;
  object-fit: cover;
  cursor: pointer;
  pointer-events: all;
}

.lightbox{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox .backdrop{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  z-index: -1;
}

.lightbox img{
  max-width: 1080px;
  margin: auto;
}

em{
  font-style: italic;
}

img {
   user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}
button{
  outline: none;
  color: var(--grey);
  border: none;
  cursor: pointer;
}

video:fullscreen,
video:-webkit-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  object-fit: contain;
}

@keyframes fade-in {
0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(135deg);
  }
  100% {
    transform: translateX(100%) rotate(135deg);
    display: none;
  }
}

.inline-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  margin: 0 24px;
  transform: scale(0.4) translateZ(0);
}

.react-pdf__Document{
  transform: scale(0.75);
  transform-origin: top left;
}

iframe{
  top: 0;
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em var(--dark), 1.8em -1.8em 0 0em rgba(46,46,46, 0.2), 2.5em 0em 0 0em rgba(46,46,46, 0.2), 1.75em 1.75em 0 0em rgba(46,46,46, 0.2), 0em 2.5em 0 0em rgba(46,46,46, 0.2), -1.8em 1.8em 0 0em rgba(46,46,46, 0.2), -2.6em 0em 0 0em rgba(46,46,46, 0.5), -1.8em -1.8em 0 0em rgba(46,46,46, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.7), 1.8em -1.8em 0 0em var(--dark), 2.5em 0em 0 0em rgba(46,46,46, 0.2), 1.75em 1.75em 0 0em rgba(46,46,46, 0.2), 0em 2.5em 0 0em rgba(46,46,46, 0.2), -1.8em 1.8em 0 0em rgba(46,46,46, 0.2), -2.6em 0em 0 0em rgba(46,46,46, 0.2), -1.8em -1.8em 0 0em rgba(46,46,46, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.5), 1.8em -1.8em 0 0em rgba(46,46,46, 0.7), 2.5em 0em 0 0em var(--dark), 1.75em 1.75em 0 0em rgba(46,46,46, 0.2), 0em 2.5em 0 0em rgba(46,46,46, 0.2), -1.8em 1.8em 0 0em rgba(46,46,46, 0.2), -2.6em 0em 0 0em rgba(46,46,46, 0.2), -1.8em -1.8em 0 0em rgba(46,46,46, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.2), 1.8em -1.8em 0 0em rgba(46,46,46, 0.5), 2.5em 0em 0 0em rgba(46,46,46, 0.7), 1.75em 1.75em 0 0em var(--dark), 0em 2.5em 0 0em rgba(46,46,46, 0.2), -1.8em 1.8em 0 0em rgba(46,46,46, 0.2), -2.6em 0em 0 0em rgba(46,46,46, 0.2), -1.8em -1.8em 0 0em rgba(46,46,46, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.2), 1.8em -1.8em 0 0em rgba(46,46,46, 0.2), 2.5em 0em 0 0em rgba(46,46,46, 0.5), 1.75em 1.75em 0 0em rgba(46,46,46, 0.7), 0em 2.5em 0 0em var(--dark), -1.8em 1.8em 0 0em rgba(46,46,46, 0.2), -2.6em 0em 0 0em rgba(46,46,46, 0.2), -1.8em -1.8em 0 0em rgba(46,46,46, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.2), 1.8em -1.8em 0 0em rgba(46,46,46, 0.2), 2.5em 0em 0 0em rgba(46,46,46, 0.2), 1.75em 1.75em 0 0em rgba(46,46,46, 0.5), 0em 2.5em 0 0em rgba(46,46,46, 0.7), -1.8em 1.8em 0 0em var(--dark), -2.6em 0em 0 0em rgba(46,46,46, 0.2), -1.8em -1.8em 0 0em rgba(46,46,46, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.2), 1.8em -1.8em 0 0em rgba(46,46,46, 0.2), 2.5em 0em 0 0em rgba(46,46,46, 0.2), 1.75em 1.75em 0 0em rgba(46,46,46, 0.2), 0em 2.5em 0 0em rgba(46,46,46, 0.5), -1.8em 1.8em 0 0em rgba(46,46,46, 0.7), -2.6em 0em 0 0em var(--dark), -1.8em -1.8em 0 0em rgba(46,46,46, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(46,46,46, 0.2), 1.8em -1.8em 0 0em rgba(46,46,46, 0.2), 2.5em 0em 0 0em rgba(46,46,46, 0.2), 1.75em 1.75em 0 0em rgba(46,46,46, 0.2), 0em 2.5em 0 0em rgba(46,46,46, 0.2), -1.8em 1.8em 0 0em rgba(46,46,46, 0.5), -2.6em 0em 0 0em rgba(46,46,46, 0.7), -1.8em -1.8em 0 0em var(--dark);
  }
}
