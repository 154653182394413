.container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12px;
  background: transparent;
  width: 100%;
  height: calc(90% - 90px);
  margin-bottom: 24px;
  color: white;
  padding: 0;
}

div[role='textbox'] {
  overflow-y: scroll;
  border: 1px solid var(--light-grey);
  max-height: 100%;
  padding: 24px;
  border-radius: 16px;
}

.editor {
  border-radius: inherit;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.editor:focus {
  outline: none;
}

.editorContainer {
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  height: 100%;
}

.editor p {
  margin: 1em 0;
}
